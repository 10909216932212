import '../../../assets/css/responsive_widget_contents_container.css'
import '../../../assets/css/widget_contents_container.css'
import React, { useEffect,useState, useCallback, memo, useRef } from 'react';
import { Responsive } from "react-grid-layout";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css'

import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@material-ui/core/Button';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from "@material-ui/core/Input";

import ListAltIcon from '@mui/icons-material/ListAlt';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CloudIcon from '@mui/icons-material/Cloud';
import EventIcon from '@mui/icons-material/Event';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import StoreIcon from '@mui/icons-material/Store';
import PaidIcon from '@mui/icons-material/Paid';
import NumbersIcon from '@mui/icons-material/Numbers';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GroupWorkIcon from '@mui/icons-material/GroupWork';

import { get_categories,get_latest_suppliers,get_offers_opening_time,getlatest_offers,
        count_allOffers_total_amount,
        count_allAwardedOffers_total_amount, count_allSubmissions, count_allAwardedOffers,
        count_AllpurchaseService_markets_bills_forWidget, count_AllpurchaseService_orders_bills_forWidget, count_AllpurchaseService_markets_contracts_forWidget,
        delay_between_offerCreation_awardedContract,count_allOffers_byCategorie_widget,
        count_allSuppliers_byCategorie_widget, bestOffers_widgetCharts, delay_between_delivery_supposedDate, chart_of_the_bestSuppliers_for_orders } from '../../../services/purchase_service';


import { getlatest_commissions } from '../../../services/commission_service';
import { get_widgets_view_byId_login, set_widgets_view_byId_login } from '../../../services/widgets_views_service'
import Backdrop from '@mui/material/Backdrop';

import Weather_widget from './weather_widget/weather_widget';
import Suppliers_table from './suppliers_table/suppliers_table';
import Charts_of_the_bestOffers from './charts_of_the_bestOffers/charts_of_the_bestOffers';
import Offers_table from './offers_table/offers_table';
import Config_widget from './config_widget/config_widgets';
import Last_commissions from './commissions_table/last_commissions'
import Scheduler_widget from './scheduler_widget/scheduler_widget'
import Offers_number_byMonth from './offers_number_byMonth/offers_number_byMonth'
import Count_offers_totalAmount from './count_offers_totalAmount/count_offers_totalAmount'
import Count_awardedOffers from './count_awardedOffers/count_awardedOffers'
import Submissions_table from './submissions_table/submissions_table'
import Delay_of_market from './delay_of_market/delay_of_market'
import OffersChart_byCategorie from './offersChart_byCategorie/offersChart_byCategorie'
import SuppliersCharts_byCategorie from './suppliersCharts_byCategorie/suppliersCharts_byCategorie'
import Charts_of_the_bestSuppliers from './charts_of_the_bestSuppliers/charts_of_the_bestSuppliers'

import RSS_widget from './rss_widget/rss_widget'
import Markets_bills from './markets_bills/markets_bills';
import Contracts_table from './contracts_table/contracts_table';
import { useMemo } from 'react';
import RequestsWithCatalogue_number_byState from './requestsWithCatalogue_number_byState/requestsWithCatalogue_number_byState';
import RequestsWithout_Catalogue_number_byState from './requestsWithout_Catalogue_number_byState/requestsWithout_Catalogue_number_byState';
import Orders_bills from './markets_bills/orders_bills';
import Requests_withcatalogue_total_price_byState from './requests_withcatalogue_total_price_byState/requests_withcatalogue_total_price_byState';
import Requests_withoutcatalogue_total_price_byState from './requests_withoutcatalogue_total_price_byState/requests_withoutcatalogue_total_price_byState';
import Delay_between_delivery_supposedDate from './delay_between_delivery_supposedDate/delay_between_delivery_supposedDate';
import Chart_of_the_bestSuppliers_for_orders from './chart_of_the_bestSuppliers_for_orders/chart_of_the_bestSuppliers_for_orders';
import { Fab, Tooltip } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1),},
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    width: '100%',
  },
  input: {
    display: 'none',
  },
  container: {
    maxHeight: 440,
  },
  select: {
    "&:focus": {
      backgroundColor: "white"
    }
  },
  selectInput: {
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottomWidth: 0
    },
    "&:after": {
      borderBottomWidth: 0
    }
  },
  disabled: {},
  focused: {},
  error: {}
}));


//////////////////////
///////////////////////////////////
function get_opening_start_DatetTme(opening_date){
  let date = new Date(opening_date);
  return new Date(date.getFullYear(),date.getMonth(),date.getDate(),date.getHours(),date.getMinutes()); 
}

function get_opening_end_DatetTme(opening_date){
  let date = new Date(opening_date);
  return new Date(date.getFullYear(),date.getMonth(),date.getDate(),(date.getHours()+2),date.getMinutes()); 
}

function random_hex_color_code(random_number){
  let n = (random_number * 0xffffff * 10000000).toString(16);
  return '#' + n.slice(0, 6);
};


function Get_widget_icon_byId(widgets_main_array,id_widget){
  return widgets_main_array.find(function(widget) {
    return widget.id === id_widget;
  }).icon;
}


//////////////////////
///////////////////////////////////



export default memo(function Widget_contents_container() {

  const classes = useStyles();
  const selectInputClasses = {
    root: classes.selectInput,
    disabled: classes.disabled,
    focused: classes.focused,
    error: classes.error
  };

  let purchaseService_infos = JSON.parse(localStorage.getItem('user-info'));
  let origin_url =  `https://rss.nytimes.com/services/xml/rss/nyt/World.xml`;
  let history = useHistory();
  const [url_updated , setUrl_updated] = useState(null);

  const width_ref = useRef(null);

  const [widgets_timeRange,setWidgets_timeRange] = useState(null);
  const [all_data_loaded,setAll_data_loaded] = useState(false);

  const [widgets_tmp_array,setWidgets_tmp_array] = useState(null);
  const [widgets_lastPosition,setWidgets_lastPosition] = useState([0,0]);

  const [city,setCity] = useState(null);
  const [latitude,setLatitude] = useState(null);
  const [longitude,setLongitude] = useState(null);
  const [news, setNews] = useState(null);

  const [sheduler_events,setSheduler_events]=useState(null);
  const [categories,setCategories]=useState(null);
  const [lastSuppliers,setLastSuppliers]=useState(null);
  const [lastoffers,setLastoffers]=useState(null);
  const [lastcommissions,setLastcommissions]=useState(null);
  const [count_allSubmissions_content,setCount_allSubmissions_content]=useState(null);
  const [count_allAwardedOffers_content,setCount_allAwardedOffers_content]=useState(null);
  const [count_Alloffers_totalAmount,setCount_Alloffers_totalAmount]=useState(null);
  const [count_AllAwardedoffers_totalAmount,setCount_AllAwardedoffers_totalAmount]=useState(null);
  const [count_AllpurchaseService_markets_bills,setCount_AllpurchaseService_markets_bills]=useState(null);
  const [count_AllpurchaseService_markets_contracts,setCount_AllpurchaseService_markets_contracts]=useState(null);
  const [market_delay,setMarket_delay]=useState(null);
  const [charts_count_offersBycategorie,setCharts_count_offersBycategorie]=useState(null);
  const [charts_count_suppliersBycategorie,setCharts_count_suppliersBycategorie]=useState(null);
  const [charts_of_the_bestOffers,setCharts_of_the_bestOffers]=useState(null);
  const [allpurchaseService_orders_bills_forWidget,setAllpurchaseService_orders_bills_forWidget]=useState(null);
  const [delay_delivery_supposedDate,setDelay_delivery_supposedDate]=useState(null);
  const [bestSuppliers_for_orders,setBestSuppliers_for_orders]=useState(null);
  
  const [count_non_displayed,setCount_non_displayed] = useState(0);
  const [waiting, setWaiting] = useState(false);


  ////////////////////////////////////////////////////////////////////////////////////////
  
  const [remain_widgets,setRemain_Widgets] = useState(null);

  let widgets_main_array = [
    //{id:"weather",itemTitle:"Météo",icon:<CloudIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 19, minW: 5,minH: 18.5 }},
    //{id:"news",itemTitle:"Actualités",icon:<NewspaperIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 23, minW: 5,minH: 22 }},
    {id:"scheduler",itemTitle:"Agenda",icon:<EventIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 23, minW: 5,minH: 22 }},
    {id:"lastSuppliers",itemTitle:"Derniers fournisseurs ajoutés",icon:<FormatListBulletedIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4,minH: 12,isDraggable: true }},
    {id:"offers_number_byMonth",itemTitle:"Nombre d'offres par mois",icon:<FormatListNumberedIcon className="my_icon" />,dataGrid:{ x: 0, y: 0,w: 6, h: 18.5, minW: 4.5,minH: 18,isDraggable: true }},
    // {id:"offres_estimations",itemTitle:"Estimation des Appels d'Offres",icon:<ListAltIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12,isDraggable: true }},
    {id:"charts_of_the_bestOffers",itemTitle:"les meilleures offres",icon:<ListAltIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 3, h: 17, minW: 3,minH: 12,isDraggable: true }},
    {id:"charts_of_the_bestActiveSuppliers",itemTitle:"Fournisseurs avec le plus grand nombre de soumissions",icon:<FormatListBulletedIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 3, h: 17, minW: 3,minH: 12,isDraggable: true }},
    {id:"charts_of_the_bestAccordedSuppliers",itemTitle:"Fournisseurs avec le plus grand nombre d'adjudications",icon:<FormatListBulletedIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 3, h: 17, minW: 3,minH: 12,isDraggable: true }},
    {id:"charts_of_the_bestRatedSuppliers",itemTitle:"Fournisseurs avec les meilleures notes",icon:<FormatListBulletedIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 3, h: 17, minW: 3,minH: 12,isDraggable: true }},
    {id:"lastOffers",itemTitle:"Appels d'offres récents",icon:<ListAltIcon className="my_icon" />,dataGrid:{ x: 0, y: 0,w: 6, h: 17, minW: 4.5,minH: 18,isDraggable: true }},
    {id:"lastCommissions",itemTitle:"Commissions en cours de traitement",icon:<PlaylistRemoveIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 18,isDraggable: true }},
    {id:"count_Alloffers_totalAmount",itemTitle:"Montant total de tous les offres",icon:<StoreIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 18,isDraggable: true }},
    {id:"count_AllAwardedoffers_totalAmount",itemTitle:"Montant total des offres adjugés",icon:<PaidIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 18,isDraggable: true }},
    {id:"count_allSubmissions",itemTitle:"Nombre des soumissions",icon:<NumbersIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 18,isDraggable: true }},
    {id:"count_allAwardedOffers",itemTitle:"Nombre des marchés Adjugés",icon:<NumbersIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 18,isDraggable: true }},
    {id:"count_AllpurchaseService_orders_bills_forWidget",itemTitle:"Nombre de factures par commande de demande",icon:<NumbersIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 18,isDraggable: true }},
    {id:"count_AllpurchaseService_markets_bills",itemTitle:"Nombre de factures sur le marché",icon:<NumbersIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 18,isDraggable: true }},
    {id:"count_AllpurchaseService_markets_contracts",itemTitle:"Nombre de contrats",icon:<NumbersIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 18,isDraggable: true }},
    {id:"delay_between_offerCreation_awardedContract",itemTitle:"Délai moyen des marchés",icon:<AccessTimeIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 18,isDraggable: true }},
    {id:"charts_count_offersBycategorie",itemTitle:"Appels d'offre par famille d'achat",icon:<GroupWorkIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 29.5, minW: 4.5,minH: 18,isDraggable: true }},
    {id:"charts_count_suppliersBycategorie",itemTitle:"Fournisseurs par famille d'achat",icon:<GroupWorkIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 29.5, minW: 4.5,minH: 18,isDraggable: true }},
    {id:"RequestsWithCatalogue_number_byState",itemTitle:"Nombre de demandes d'achat avec catalogue",icon:<NumbersIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 18,isDraggable: true }},
    {id:"RequestsWithoutCatalogue_number_byState",itemTitle:"Nombre de demandes d'achat sans catalogue",icon:<NumbersIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 18,isDraggable: true }},
    {id:"requests_withcatalogue_total_price_byState",itemTitle:"Montant total des demandes d'achat avec catalogue",icon:<PaidIcon  className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"requests_withoutcatalogue_total_price_byState",itemTitle:"Montant total des demandes d'achat sans catalogue",icon:<PaidIcon  className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"delay_between_delivery_supposedDate",itemTitle:"Délai moyen des demandes d'achat",icon:<AccessTimeIcon  className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"chart_of_the_bestSuppliers_for_orders",itemTitle:"Fournisseurs traitent plus de commandes",icon:<FormatListBulletedIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 3, h: 17, minW: 3,minH: 12,isDraggable: true }}
   ];

  let widgets_main_tmp_array = [
    {id:"charts_count_offersBycategorie",itemTitle:"Appels d'offre par famille d'achat",icon:<GroupWorkIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 29.5, minW: 4.5,minH: 18,isDraggable: true }},
    {id:"lastOffers",itemTitle:"Appels d'offres récents",icon:<ListAltIcon className="my_icon" />,dataGrid:{ x: 0, y: 0,w: 6, h: 17, minW: 4.5,minH: 18,isDraggable: true }},
    {id:"lastSuppliers",itemTitle:"Derniers fournisseurs ajoutés",icon:<FormatListBulletedIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4,minH: 12,isDraggable: true }},
    {id:"charts_of_the_bestActiveSuppliers",itemTitle:"Fournisseurs avec le plus grand nombre de soumissions",icon:<FormatListBulletedIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 3, h: 17, minW: 3,minH: 12 ,isDraggable: true}},
    {id:"charts_of_the_bestRatedSuppliers",itemTitle:"Fournisseurs avec les meilleures notes",icon:<FormatListBulletedIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 3, h: 17, minW: 3,minH: 12,isDraggable: true }},
    {id:"count_Alloffers_totalAmount",itemTitle:"Montant total de tous les offres",icon:<StoreIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 18,isDraggable: true }},
    {id:"charts_count_suppliersBycategorie",itemTitle:"Fournisseurs par Famille d'achat",icon:<GroupWorkIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 29.5, minW: 4.5,minH: 18,isDraggable: true }}
  ];


  const load_widgets_data = useCallback(async (widgets,service_infos,time_range) => {
    for(let i in widgets){
      let widget = widgets[i];
      if(widget.id==="lastSuppliers"){
        setLastSuppliers(null);
        await get_latest_suppliers(time_range).then((response)=> {
          setLastSuppliers(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        });
      } 
      if(widget.id==="weather"){
        const location = window.navigator && window.navigator.geolocation

        if (location) {
           await location.getCurrentPosition((position) => {
              //console.log(position);
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
                axios('https://www.mapquestapi.com/geocoding/v1/reverse?key=G1moSFJkXvMTf7kCVqTOPMh1SxtvJaGi&location='+position.coords.latitude+'%2C'+position.coords.longitude+'&outFormat=json&thumbMaps=false')
                    .then((response) => {
                      setCity(response.data.results[0].locations[0].adminArea5);
                    });
            }, (error) => {
                console.log(error);
            })
        }

      }
      if(widget.id==="news"){
        await fetch(origin_url)
        .then(response => response.text())
        .then(data => {
            let tmp_array = [];
            const parser = new DOMParser();
            const xml = parser.parseFromString(data, "text/xml");
            //console.log((xml.getElementsByTagName("item")[0]));
            for(let i=0;i<6;i++){
                tmp_array.push(xml.getElementsByTagName("item")[i]);
            }

            setNews(tmp_array);
        })
        .catch(console.error);   
      }
      if(widget.id==="scheduler"){
        let tmp_list=[];
        let random_number = 0;
        setSheduler_events(null);
        await get_offers_opening_time(service_infos.id).then((response)=>{
              response.data.map((elem,id) => {
                let event = {
                  id_event : "offer_"+id,
                  start: get_opening_start_DatetTme(elem.dateOuvertureOffre),
                  end: get_opening_end_DatetTme(elem.dateOuvertureOffre), 
                  title: elem.object, 
                  familleAchat: elem.familleAchat, 
                  direction:elem.direction,
                  color:random_hex_color_code(random_number)
                }
                tmp_list.push(event);
                random_number+=1.03; 
              });
              setSheduler_events(tmp_list);
              //console.log(tmp_list);
          }).catch((error)=>{
              console.log(error);
          });
      }
      if(widget.id==="best_suppliers"){

      }
      if(widget.id==="lastOffers"){
        await getlatest_offers(time_range).then((response_offers)=> {
            setLastoffers(response_offers.data);
        }).catch((error) => {
            console.log(error);
        });
      }
      if(widget.id==="lastCommissions"){
        await getlatest_commissions(time_range).then((response_commissions)=> {
          setLastcommissions(response_commissions.data);
        }).catch((error) => {
            //console.log(error.response);
        });
      }
      if(widget.id==="count_Alloffers_totalAmount"){
        await count_allOffers_total_amount(time_range).then((response)=> {
          setCount_Alloffers_totalAmount(response.data);
        }).catch((error) => {
            //console.log(error.response);
        });
      }
      if(widget.id==="count_AllAwardedoffers_totalAmount"){
        await count_allAwardedOffers_total_amount(time_range).then((response)=>{
          setCount_AllAwardedoffers_totalAmount(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        })
      }
      if(widget.id==="count_allSubmissions"){
        await count_allSubmissions(time_range).then((response)=>{
          setCount_allSubmissions_content(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        })
      }
      if(widget.id==="count_allAwardedOffers"){
        await count_allAwardedOffers(time_range).then((response)=>{
          setCount_allAwardedOffers_content(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        })
      }
      if(widget.id==='count_AllpurchaseService_markets_bills'){
        await count_AllpurchaseService_markets_bills_forWidget(time_range).then((response)=>{
          setCount_AllpurchaseService_markets_bills(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        })
      }
      if(widget.id==='count_AllpurchaseService_markets_contracts'){
        await count_AllpurchaseService_markets_contracts_forWidget(time_range).then((response)=>{
          setCount_AllpurchaseService_markets_contracts(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        })
      }
      if(widget.id==='delay_between_offerCreation_awardedContract'){
        await delay_between_offerCreation_awardedContract(widgets_timeRange).then((response)=>{
          setMarket_delay(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        })
      }
      if(widget.id==='charts_count_offersBycategorie'){
        await count_allOffers_byCategorie_widget(time_range).then((response)=>{
          setCharts_count_offersBycategorie(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        })
      }
      if(widget.id==='charts_count_suppliersBycategorie'){
        await count_allSuppliers_byCategorie_widget(time_range).then((response)=>{
          setCharts_count_suppliersBycategorie(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        })
      }
      if(widget.id==="charts_of_the_bestOffers"){
        await bestOffers_widgetCharts(time_range).then((response)=>{
          setCharts_of_the_bestOffers(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        })
      }
      if(widget.id==="count_AllpurchaseService_orders_bills_forWidget"){
        await count_AllpurchaseService_orders_bills_forWidget(time_range).then((response)=>{
          setAllpurchaseService_orders_bills_forWidget(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        })
      }
      if(widget.id==="delay_between_delivery_supposedDate"){
        await delay_between_delivery_supposedDate(service_infos.id,time_range).then((response)=> {
          setDelay_delivery_supposedDate(response.data);
        }).catch((error) => {
          //console.log(error.response);
        });
      }
      if(widget.id==="chart_of_the_bestSuppliers_for_orders"){
        await chart_of_the_bestSuppliers_for_orders(service_infos.id,time_range).then((response)=> {
          setBestSuppliers_for_orders(response.data);
        }).catch((error) => {
          //console.log(error.response);
        });
      }
    }
    
  },[])
  

    const set_categories =  useCallback(async () => {
      await get_categories().then((response)=> {
        setCategories(response.data);
      }).catch((error)=>{
        //console.log(error.response);
      });
    },[]);

   useEffect(() => {
      setWidgets_tmp_array(null);
      setAll_data_loaded(false);
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      var time_range = urlParams.get('intervalle_temps')?urlParams.get('intervalle_temps'):"mois";

      async function set_time(time_range){
        await setWidgets_timeRange(time_range);
      }

      async function setData(){
          set_categories();
          const widget_data = await get_widgets_view_byId_login(purchaseService_infos.id).then((response)=>{
                return response.data;
          }).catch((error)=>{
            //console.log(error.response);
          });
          if(widget_data === "empty"){
            await load_widgets_data(widgets_main_tmp_array,purchaseService_infos,time_range);
            await setWidgets_tmp_array(set_widgets_grides_data(widgets_main_tmp_array));
            await setCount_non_displayed(widgets_main_array.length-widgets_main_tmp_array.length);
          }else{
            await setCount_non_displayed(widgets_main_array.length-widget_data.length);
            await load_widgets_data(widget_data,purchaseService_infos,time_range);
            await setWidgets_tmp_array(widget_data);
          }
        }
        async function data_loaded(){
          setAll_data_loaded(true);
        }

        async function async_funct(){
          await set_time(time_range);
          await setData();
          await data_loaded();
        }
        
        async_funct();

  },[url_updated,history.location]);

/*
  useLayoutEffect(() => {
    //console.log(width_ref.current.offsetWidth);
  }, []);
*/


  const set_widgets_grides_data = useCallback((widgets_array) => {
    let tmp_array = [];
    for(let i=0 ; i<widgets_array.length ; i++){
      let previous_elem_width = 0;
      let previous_elem_height = 0;
      let previous_elem_x = 0;
      let previous_elem_y = 0;
      if(i===0){
        tmp_array.push(widgets_array[i]);
        setWidgets_lastPosition([widgets_array[i].dataGrid.x + widgets_array[i].dataGrid.w,widgets_array[i].dataGrid.y + widgets_array[i].dataGrid.h])
      }
      else{
        previous_elem_width = widgets_array[i-1].dataGrid.w;
        previous_elem_height = widgets_array[i-1].dataGrid.h;
        previous_elem_x = widgets_array[i-1].dataGrid.x;
        previous_elem_y = widgets_array[i-1].dataGrid.y;
        if((previous_elem_x + previous_elem_width + widgets_array[i].dataGrid.w)<=12){
          widgets_array[i].dataGrid.x = previous_elem_width+previous_elem_x;
          widgets_array[i].dataGrid.y = previous_elem_y;
        }else{
          if( previous_elem_y != widgets_array[i].dataGrid.y ) widgets_array[i].dataGrid.x = 0;
          widgets_array[i].dataGrid.y = previous_elem_height+previous_elem_y;
        }
        setWidgets_lastPosition([widgets_array[i].dataGrid.x + widgets_array[i].dataGrid.w,widgets_array[i].dataGrid.y + widgets_array[i].dataGrid.h])
        tmp_array.push(widgets_array[i]);
      }
    }
    //console.log(widgets_lastPosition);
    return tmp_array;
  },[])
    
  const get_widgets_data_to_save = useCallback((widgets) => {
    let tmp_array = [];
    let tmp_json = {};
    widgets.map((widget)=>{
      tmp_json = {};
      tmp_json.id = widget.id;
      tmp_json.itemTitle = widget.itemTitle;
      tmp_json.dataGrid = widget.dataGrid;
      tmp_array.push(tmp_json);
    })
    return tmp_array;
  },[])


  const removeWidget = useCallback((idx) => {
    setCount_non_displayed(count_non_displayed+1);
    setWidgets_tmp_array(current =>
      current.filter(widget => {
        return widget.id !== idx;
      })
    );
  },[count_non_displayed])


  const show_remain_widgets = () => {
    let tmp_array = [];
    widgets_main_array.forEach(main_widget => {
      let exist = false;
      widgets_tmp_array && widgets_tmp_array.map((tmp_widget)=>{
        if(main_widget.id === tmp_widget.id) exist = true;
      })
      if(!exist) tmp_array.push(main_widget);
    });
    setRemain_Widgets(tmp_array);
  }


  const add_widget = useCallback(async (remain_widget)=>{
    if(widgets_tmp_array ){
      let tmp_array = [ ...widgets_tmp_array];
      let array_previous_lenght = tmp_array.length;
      if(array_previous_lenght!=0){
        let previous_widgets_lastPosition_elem_x = widgets_lastPosition[0];
        let previous_widgets_lastPosition_elem_y = widgets_lastPosition[1];
        if((previous_widgets_lastPosition_elem_x + remain_widget.dataGrid.w)<=12){
          remain_widget.dataGrid.x = previous_widgets_lastPosition_elem_x;
          remain_widget.dataGrid.y = previous_widgets_lastPosition_elem_y;
        }else{
          remain_widget.dataGrid.y = previous_widgets_lastPosition_elem_y;
        }
      }
      await setWidgets_lastPosition([remain_widget.dataGrid.x + remain_widget.dataGrid.w,remain_widget.dataGrid.y + remain_widget.dataGrid.h]);
      await setRemain_Widgets(null);
      setWaiting(true);   
      await load_widgets_data([remain_widget],purchaseService_infos,widgets_timeRange);
      setWaiting(false);   
      setWidgets_tmp_array([...widgets_tmp_array, remain_widget]);
      await setCount_non_displayed(count_non_displayed-1);
    }
  },[widgets_tmp_array])

  
  const set_widgets_gridData = useCallback(async (dataGrids)=>{
    if(widgets_tmp_array ){
      let tmp_array = [...widgets_tmp_array];
      for(let i=0 ; i<tmp_array.length ; i++){
        for(let i in dataGrids){
          let dataGrid = dataGrids[i];
          if(tmp_array[i].id===dataGrid.i){
           if(tmp_array[i].dataGrid.x !== dataGrid.x || tmp_array[i].dataGrid.y !== dataGrid.y || tmp_array[i].dataGrid.w !== dataGrid.w || tmp_array[i].dataGrid.h !== dataGrid.h){
              tmp_array[i].dataGrid = dataGrid;
              if(tmp_array[i].dataGrid.x + tmp_array[i].dataGrid.w > widgets_lastPosition[0] && tmp_array[i].dataGrid.y + tmp_array[i].dataGrid.h >= widgets_lastPosition[1] ){
                await setWidgets_lastPosition([tmp_array[i].dataGrid.x + tmp_array[i].dataGrid.w,tmp_array[i].dataGrid.y + tmp_array[i].dataGrid.h]);
              }
            }
          }
        }
      }
    }
  },[widgets_lastPosition, widgets_tmp_array])


 const set_widgets_view  = useCallback((purchaseService_infos,widgets_tmp_array) => {
    setWaiting(true);
    let widgets_data_to_save = get_widgets_data_to_save(widgets_tmp_array);
    let formData = new FormData();
    formData.append('id_login',purchaseService_infos.id);
    formData.append('widgets_view',JSON.stringify(widgets_data_to_save));
    set_widgets_view_byId_login(formData).then((response)=>{
        setWaiting(false);
    }).catch((error)=>{
      //console.log(error.response);
    });
  },[])

 function set_timeRange(value){
   if(value!==widgets_timeRange){
    let path = history.location.pathname;
    history.push(path+"?intervalle_temps="+value); 
    setUrl_updated(value);
   }
  }

  let lastClick = 0;
  const stop_dragging = useCallback(async (e,id)=>{
    let date = new Date();
    let time = date.getTime();
    const time_between_taps = 40;
    if (time - lastClick < time_between_taps) {
    }else{    
      e.stopPropagation();
      if(widgets_tmp_array ){
        let tmp_array = [ ...widgets_tmp_array ];
        tmp_array[id].dataGrid.isDraggable=false;
      }
    }
    lastClick = time;

  },[widgets_tmp_array])

  const active_dragging  = useCallback(async (id)=>{
    if(widgets_tmp_array ){
      let tmp_array = [ ...widgets_tmp_array ];
      tmp_array[id].dataGrid.isDraggable=true;
    }
  },[widgets_tmp_array])

  const cachedValue = useMemo(() => {
    return widgets_tmp_array;
  }, [widgets_tmp_array])

  const widgets_timeRange_call =useMemo(() => {
    return widgets_timeRange;
  }, [widgets_timeRange]) 

  const test = useMemo(() => { 
    return(
    <React.Fragment>
    {
      cachedValue!=null && all_data_loaded===true
       ?<Responsive
         className="layout"
         breakpoints={{ lg: 1130, md: 870, sm: 670, xs: 400, xxs: 0 }}
         cols={{ lg: 12, md: 11, sm: 9, xs: 6, xxs: 6 }}
         style={{width:"100%",height:"99%"}}
         margin={{ lg: [14,17], md: [10,15], sm: [9,14], xs: [8,13], xxs: [7,11] }}
         rowHeight={1}
         width={width_ref.current.offsetWidth - 4}
         autoSize={true}
         onLayoutChange={(e) => { set_widgets_gridData(e) }}
       >
           {
             cachedValue.map((widget,idx)=>{
                 return (
                   <div className="widget_container" key={widget.id} id={idx} data-grid={widget.dataGrid}>
                       <div className='item_title' style={{display:widget.id==="weather"?"none":"flex"}} >
                          <div style={{display:'flex'}}>
                            <span style={{display:'flex',marginRight:'5px',justifyContent:'center',alignItems:'center'}} >{Get_widget_icon_byId(widgets_main_array,widget.id)}</span>
                            <span style={{lineHeight:'.95rem',alignSelf:'center'}}>{widget.itemTitle}</span>
                          </div>
                          <div>
                              <CloseSharpIcon onTouchStart={(e)=>{e.stopPropagation();removeWidget(widget.id)}} 
                                onClick={(e)=>{e.stopPropagation();e.preventDefault();removeWidget(widget.id)}} className="my_icon" style={{cursor: "pointer"}} />
                          </div>
                      </div> 
                      <div className="widget_component_container" id={"widget_component_container"+idx}
                        onTouchStartCapture={(e)=>{stop_dragging(e,idx);
                            e.currentTarget.addEventListener('touchend',()=>{
                              active_dragging(idx);
                          }, {passive: false});
                        }}>
                        {
                          (widget.id==="lastSuppliers" && lastSuppliers!=null)?<Suppliers_table categories={categories} lastSuppliers={lastSuppliers} />
                          :(widget.id==="offers_number_byMonth")?<Offers_number_byMonth categories={categories} time_range={widgets_timeRange} />
                          :(widget.id==="weather" && latitude!=null && longitude!=null && city!=null)?<Weather_widget latitude={latitude} longitude={longitude} city={city} removeWidget={()=>removeWidget(widget.id)} />
                          :(widget.id==="news")?<RSS_widget news={news} />
                          :(widget.id==="scheduler" && sheduler_events!=null)?<Scheduler_widget sheduler_events={sheduler_events} categories={categories} />
                          :(widget.id==="charts_of_the_bestOffers" && charts_of_the_bestOffers!=null)?<Charts_of_the_bestOffers data={charts_of_the_bestOffers}/>
                          :(widget.id==="charts_of_the_bestActiveSuppliers" && widgets_timeRange!=null )?<Charts_of_the_bestSuppliers categories={categories} data="charts_of_the_bestActiveSuppliers" time_range={widgets_timeRange} />
                          :(widget.id==="charts_of_the_bestAccordedSuppliers" && widgets_timeRange!=null)?<Charts_of_the_bestSuppliers categories={categories} data="charts_of_the_bestAccordedSuppliers" time_range={widgets_timeRange}  />
                          :(widget.id==="charts_of_the_bestRatedSuppliers" && widgets_timeRange!=null)?<Charts_of_the_bestSuppliers categories={categories} data="charts_of_the_bestRatedSuppliers" time_range={widgets_timeRange}  />
                          :(widget.id==="lastOffers" && lastoffers!=null)?<Offers_table categories={categories} data={lastoffers} />
                          :(widget.id==="lastCommissions" && lastcommissions!=null)?<Last_commissions categories={categories} data={lastcommissions} />
                          :(widget.id==="count_allSubmissions" && count_allSubmissions_content!=null)?<Submissions_table categories={categories} data={count_allSubmissions_content}  />
                          :(widget.id==="count_allAwardedOffers" && count_allAwardedOffers_content!=null)?<Count_awardedOffers categories={categories} data={count_allAwardedOffers_content} />
                          :(widget.id==="count_Alloffers_totalAmount" && count_Alloffers_totalAmount!=null)?<Count_offers_totalAmount categories={categories} data={count_Alloffers_totalAmount} />
                          :(widget.id==="count_AllAwardedoffers_totalAmount" && count_AllAwardedoffers_totalAmount!=null)?<Count_offers_totalAmount categories={categories} data={count_AllAwardedoffers_totalAmount} />
                          :(widget.id==="count_AllpurchaseService_markets_bills" && count_AllpurchaseService_markets_bills!=null)?<Markets_bills data={count_AllpurchaseService_markets_bills} />
                          :(widget.id==="count_AllpurchaseService_orders_bills_forWidget" && allpurchaseService_orders_bills_forWidget!=null)?<Orders_bills data={allpurchaseService_orders_bills_forWidget} />
                          :(widget.id==="count_AllpurchaseService_markets_contracts" && count_AllpurchaseService_markets_contracts!=null)?<Contracts_table categories={categories} data={count_AllpurchaseService_markets_contracts} />
                          :(widget.id==="delay_between_offerCreation_awardedContract")?<Delay_of_market data={market_delay} key="delay_between_offerCreation_awardedContract" />
                          :(widget.id==="RequestsWithCatalogue_number_byState")?<RequestsWithCatalogue_number_byState time_range={widgets_timeRange}key="RequestsWithCatalogue_number_byState" />
                          :(widget.id==="RequestsWithoutCatalogue_number_byState")?<RequestsWithout_Catalogue_number_byState time_range={widgets_timeRange} key="RequestsWithoutCatalogue_number_byState" />
                          :(widget.id==="charts_count_offersBycategorie" && charts_count_offersBycategorie!=null)?<OffersChart_byCategorie categories={categories} data={charts_count_offersBycategorie} />
                          :(widget.id==="charts_count_suppliersBycategorie" && charts_count_suppliersBycategorie!=null)?<SuppliersCharts_byCategorie categories={categories} data={charts_count_suppliersBycategorie} />
                          :(widget.id==="requests_withcatalogue_total_price_byState")?<Requests_withcatalogue_total_price_byState id_purchaseserv={purchaseService_infos.id} time_range={widgets_timeRange}/>
                          :(widget.id==="requests_withoutcatalogue_total_price_byState")?<Requests_withoutcatalogue_total_price_byState id_purchaseserv={purchaseService_infos.id} time_range={widgets_timeRange}/>
                          :(widget.id==="delay_between_delivery_supposedDate" && delay_delivery_supposedDate!=null )?<Delay_between_delivery_supposedDate data={delay_delivery_supposedDate}  />
                          :(widget.id==="chart_of_the_bestSuppliers_for_orders" && bestSuppliers_for_orders!=null )?<Chart_of_the_bestSuppliers_for_orders data={bestSuppliers_for_orders}/>
                          :<></>
                        } 
                      </div>
                   </div>
                 )
                 
             })
            }    
                   
       </Responsive>
       :<Box sx={{ display: 'flex' }}>
           <CircularProgress />
         </Box>
       } 
       </React.Fragment>
  )
  },[cachedValue,all_data_loaded]);

  return (
  <>
   <div className="widgets_main_container" style={{height:"100%"}}>
     <div className="widgets_view_title">
        <div className='main_title'>Tableau de bord</div>
        <div className="widgets_configButton_container">
          {
            widgets_timeRange_call!=null
            ?<div className="month_year_switchercontainer">
                <Button variant="contained" className={widgets_timeRange_call==="mois"?"selected_button":"unselected_button"} onClick={()=>set_timeRange("mois")} > Mois </Button>
                <FormControl variant="standard" className="year_slct_form" >
                  <Select required value={widgets_timeRange_call.split("_")[0]==="annee" && widgets_timeRange_call.split("_").length!==1?widgets_timeRange_call:"annee"} 
                        onChange={e => { set_timeRange(e.target.value) }}
                        input={<Input classes={selectInputClasses} />}
                        inputProps={{ classes: { select: classes.select } }}
                        className={widgets_timeRange_call.split("_")[0]==="annee" && widgets_timeRange_call.split("_").length!==1?"active_select":"inactive_select"}
                        >
                      
                      <MenuItem value={"annee"} disabled>Annee</MenuItem>
                      <MenuItem value={"annee_"+(new Date()).getFullYear()}>{(new Date()).getFullYear()}</MenuItem>
                      <MenuItem value={"annee_"+((new Date()).getFullYear()-1)}>{(new Date()).getFullYear()-1}</MenuItem>
                      <MenuItem value={"annee_"+((new Date()).getFullYear()-2)}>{(new Date()).getFullYear()-2}</MenuItem>
                      
                  </Select>
                </FormControl>
              </div>
            :<></>
          }
          <div className="widgets_configButtons">
                <Tooltip title="Sauvegarder">
                  <Fab className="widgets_saveButton" onClick={() => set_widgets_view(purchaseService_infos,widgets_tmp_array)} >
                    <SaveAsIcon className="my_icon" />
                  </Fab>
                </Tooltip>
              {
                count_non_displayed === 0
                ?<Tooltip title="Widget">
                  <Fab className="widgets_configButton disabled_button" >
                    <DashboardCustomizeIcon className="my_icon" />
                  </Fab>
                </Tooltip>
                :<Tooltip title="Widget">
                  <Fab className="widgets_configButton" onClick={show_remain_widgets} >
                    <DashboardCustomizeIcon className="my_icon" />
                  </Fab>
                </Tooltip>
              }
          </div>
            
            
        </div>
     </div>

      
      <div key="main" className="widgets_contents_container" ref={width_ref}>
              {test}
      </div>
    </div>

    {

      waiting===true
      ?<Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
      :<></>
    }

      {
        remain_widgets!=null
        ?<Config_widget add_widget={add_widget} remain_widgets={remain_widgets} setRemain_Widgets={setRemain_Widgets} widgets_tmp_array={cachedValue} /> 
        :<></>
      }

    </>
      
  );
})