import './edit_request_off_catalogue.css';
import React, { useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import swal from 'sweetalert';

import { get_applicant_request_details } from '../../../../services/purchase_service';
import { edit_purchase_request } from '../../../../services/for_allServices';
import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';
import Add_product_modal from './add_product_modal/add_product_modal';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    input: {
        display: 'none',
      },
  }));


function Edit_request_off_catalogue({open_editRequest_modal,setOpen_editRequest_modal}) {

    let user=JSON.parse(localStorage.getItem('user-info'))
    const id_service=user.id;
    const classes = useStyles();

    const [data_loaded,setData_loaded] = useState(null);
    const [articles,setArticles]=useState([]);

    const [firstName,setFirstName]=useState('');
    const [lastName,setLastName]=useState('');
    const [departement,setDepartement]=useState('');

    const [email,setEmail]=useState('');
    const [phoneNumber,setPhoneNumber]=useState('');

    const [country,setCountry]=useState('');
    const [city,setCity]=useState('');
    const [address,setAddress]=useState('');
    const [postCode,setPostCode]=useState('');
    const [supposedDelivery_date,setSupposedDelivery_date]=useState('');

    const [object,setObject]=useState('');
    const [description,setDescription]=useState('');
    const [waiting, setWaiting] = useState(false);

    const [errorText,setErrorText] = useState(null);
    const [errorEmail,setErrorEmail] = useState(null);


    useEffect(() => {
        const formData = new FormData();
        formData.append('request_code',open_editRequest_modal.get('request_code'));
        get_applicant_request_details(formData).then((response)=>{
            let details = response.data;
            setData_loaded(response.data);
            setFirstName(details.requester_firstName);
            setLastName(details.requester_lastName);
            setDepartement(details.requester_departement);
            setEmail(details.requester_email);
            setPhoneNumber(details.requester_phoneNumber);
            setCountry(details.delivery_country);
            setCity(details.delivery_city);
            setAddress(details.delivery_address);
            setPostCode(details.delivery_postCode);
            setSupposedDelivery_date(details.delivery_supposedDate.split(' ')[0]);
            setObject(details.object);
            setDescription(details.off_catalogue);
            setArticles(JSON.parse(details.products).articles);
        }).catch((error)=>{
            //console.log(error.response);
        })
    },[])
    //////////////////////////////
    /////////////email validation
    function invalid_email_pattern(email){
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if(email!==""){
            if (!pattern.test(email)) {
                return true ;
            }else{
                return false ;
            }
        }else return true;
    }

    function submit(){
        setErrorText(null);
        setErrorEmail(null);
        if(firstName==="" || lastName==="" || departement==="" || email==="" || phoneNumber===""
            || country==="" || city==="" || address==="" || postCode==="" || supposedDelivery_date==="" || description==="" || object===""){
            setErrorText("Champs invalid !");
        }else{
            if(invalid_email_pattern(email)){
                setErrorEmail("Email invalid !");
            }else{
                if(articles.length===0){
                    setErrorEmail("Pouvez-vous décrire également vos articles !");
                }else{
                    swal({
                        title: "Etes-vous sûr ?",
                        text: "!!!",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    })
                    .then((willDelete) => {
                      if (willDelete) {
                        setWaiting(true);
                        let formData = new FormData();
                        formData.append('request_code',open_editRequest_modal.get('request_code'));
                        formData.append('requester_firstName',firstName);
                        formData.append('requester_lastName',lastName);
                        formData.append('requester_departement',departement);
                        formData.append('requester_email',email);
                        formData.append('requester_phoneNumber',phoneNumber);
                        formData.append('delivery_country',country);
                        formData.append('delivery_city',city);
                        formData.append('delivery_address',address);
                        formData.append('delivery_postCode',postCode);
                        formData.append('delivery_supposedDate',supposedDelivery_date);
                        formData.append('off_catalogue',description);
                        formData.append('object',object);
                        formData.append('requested_by',id_service);
                        formData.append('products',JSON.stringify({"articles":articles}));
                        edit_purchase_request(formData).then((response)=>{
                            //console.log(response.data);
                            setWaiting(false);
                            swal("votre réponse a été enregistrée avec succès !", {
                                icon: "success",
                                });
                        }).catch((error)=>{
                            //console.log(error.response);
                            setWaiting(true);
                        })
                     }
                })
            }
        }
        }
    }

    function remove_article(id){
        let tmp_array = [ ...articles ];
        tmp_array.splice(id,1);
        setArticles(tmp_array);
    }

//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

  return (
    <Dialog disableEnforceFocus fullScreen open={open_editRequest_modal?true:false} onClose={() => setOpen_editRequest_modal(null)} className="my_full_dialog contract_full_dialog">
      <AppBar className={classes.appBar}  >
        <Toolbar style={{backgroundColor:'#343a40'}} >
                <div className='page_title'>
                    <IconButton edge="start" color="inherit"  onClick={() => setOpen_editRequest_modal(null)}aria-label="close" >
                        <CloseIcon className="my_icon" />
                    </IconButton>
                    <span>{ open_editRequest_modal.get('request_code') }</span>
                </div>
        </Toolbar>
      </AppBar>
      <div className="order_off_catalogue_form_modal_container order_off_catalogue_form_page_container"> 
        {
            data_loaded!=null
            ?<>
                <div className='off_catalogue_form_articles_container articles_container'>
                    <Articles_view articles={articles} setArticles={setArticles} /> 
                    {
                        articles.map((article,id) => (
                            <Article_content number={id+1} description={article} remove_article={remove_article} id={id} />
                        ))
                    }
                </div>
                <div className='off_catalogue_form_container'>
                <div className="order_off_catalogue_form_infos_container order_form_container">  
                    <div className="order_form_title">Demande d'achat hors catalogue</div>
                    <div className="order_form_infos_container">
                    <div className='order_form_infos_title'> 1 - Infos personnel</div>
                    <Row className="mb-12">
                            <Form.Group className="col-6">
                                <label>Nom</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Nom *"
                                    value={lastName}
                                    onChange={ e =>  {setLastName(e.target.value)}}
                                    className={ errorText && lastName==="" ? "is-invalid":""}
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Prénom</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Prénom *"
                                    value={firstName}
                                    onChange={ e =>  {setFirstName(e.target.value)}}
                                    className={ errorText && firstName==="" ? "is-invalid":""}
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Département</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Département *"
                                    value={departement}
                                    onChange={ e =>  {setDepartement(e.target.value)}}
                                    className={ errorText && departement===""  ? "is-invalid":""}
                                />           
                            </Form.Group>
                            <Form.Group className="col-6"> </Form.Group>
                            <Form.Group className="col-6">
                                <label>Email</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="email"
                                    placeholder="Email *"
                                    value={email}
                                    onChange={ e =>  {setEmail(e.target.value)}}
                                    className={ errorText && email==="" ? "is-invalid":""}
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Numéro de téléphone</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Numéro de téléphone *"
                                    value={phoneNumber}
                                    onChange={ e =>  {setPhoneNumber(e.target.value)}}
                                    className={ errorText && phoneNumber==="" ? "is-invalid":""}
                                />
                            </Form.Group>
                    </Row>
                    <div className='order_off_catalogue_form_infos_title'>2 - Infos de livraison</div>
                    <Row className="mb-12">
                            <Form.Group className="col-6">
                                <label>Pays</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Pays *"
                                    value={country}
                                    onChange={ e =>  {setCountry(e.target.value)}}
                                    className={ errorText && country==="" ? "is-invalid":""}
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Ville</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Ville *"
                                    value={city}
                                    onChange={ e =>  {setCity(e.target.value)}}
                                    className={ errorText && city==="" ? "is-invalid":""}
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Adresse</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Adresse *"
                                    value={address}
                                    onChange={ e =>  {setAddress(e.target.value)}}
                                    className={ errorText && address===""  ? "is-invalid":""}
                                />           
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Code postal</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Code postal *"
                                    value={postCode}
                                    onChange={ e =>  {setPostCode(e.target.value)}}
                                    className={ errorText && postCode===""  ? "is-invalid":""}
                                />           
                            </Form.Group>

                            <Form.Group className="col-6">
                                <label>Date de livraison souhaitée</label>
                                <Form.Control
                                    type="date"
                                    placeholder="Date de livraison souhaitée *"
                                    value={supposedDelivery_date}
                                    onChange={ e =>  {setSupposedDelivery_date(e.target.value)}}
                                    className={ errorText && supposedDelivery_date===""  ? "is-invalid":""}
                                />
                            </Form.Group>
                    </Row>
                    <div className='order_off_catalogue_form_infos_title'>3 - Informations sur la demande</div>
                    <Row className="mb-12">
                            <Form.Group className="col-md-12">
                                <label>Description de la demande</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    as="textarea" rows="5" 
                                    placeholder="Description de la demande *"
                                    value={description}
                                    onChange={ e =>  {setDescription(e.target.value)}}
                                    className={ errorText && object==="" ? "is-invalid":""}
                                />
                            </Form.Group>
                            <Form.Group className="col-md-12">
                                <label>Objet de la demande</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    as="textarea" rows="3" 
                                    placeholder="Objet de la demande *"
                                    value={object}
                                    onChange={ e =>  {setObject(e.target.value)}}
                                    className={ errorText && object==="" ? "is-invalid":""}
                                />
                            </Form.Group>
                            {
                                <p className={errorText || errorEmail ? "col-md-11 error" : "col-md-11 valid-champ"} style={{margin:'auto'}}>
                                        {errorText || errorEmail}
                                </p>
                            }
                    </Row>
                    </div>
                </div>
                <div className="order_off_catalogue_form_page_buttons_container">
                        <Button variant="contained" className={"submit_but"} endIcon={<NavigateNextIcon />} onClick={submit}> 
                                Modifier la demande
                        </Button>
                    </div>
                </div>
            </>
            :<Loading_animation_view />
        }
        </div>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>

     </Dialog>
    );
 }

export default Edit_request_off_catalogue;


function Articles_view({articles,setArticles}){
    const [open_add_product_modal,setOpen_add_product_modal] = useState(null);

    return (
        <div className='articles_view_container'>
            <div className="articles_view_header_container">
                <span>Articles</span>
                <span className="add_icon_container" onClick={() => setOpen_add_product_modal(true) }><AddIcon /></span>
            </div>
            {
                open_add_product_modal!==null
                ?<Add_product_modal open_add_product_modal={open_add_product_modal} 
                        setOpen_add_product_modal={setOpen_add_product_modal} articles={articles} setArticles={setArticles} />
                :<></>
            }
        </div>
    )
}

function Article_content({number,description,remove_article,id}){
    return (
        <div className='articles_view_container'>
            <div className="article_view_header_container">
                <span>Article n°{number}</span>
                <span className="add_icon_container" onClick={() => remove_article(id)}><RemoveIcon /></span>
            </div>
            <div>
                {description}
            </div>
        </div>
    )
}