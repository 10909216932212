import '../../../assets/css/responsive_widget_contents_container.css'
import '../../../assets/css/widget_contents_container.css'
import React, { useEffect,useState,useCallback, useRef, useMemo } from 'react';
import { Responsive } from "react-grid-layout";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { makeStyles } from '@material-ui/core/styles';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css'

import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@material-ui/core/Button';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import FormControl from '@material-ui/core/FormControl';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from "@material-ui/core/Input";

import ListAltIcon from '@mui/icons-material/ListAlt';
import CloudIcon from '@mui/icons-material/Cloud';
import EventIcon from '@mui/icons-material/Event';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import NoteIcon from '@mui/icons-material/Note';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NumbersIcon from '@mui/icons-material/Numbers';
import BarChartIcon from '@mui/icons-material/BarChart';
import WorkIcon from '@mui/icons-material/Work';
import PaidIcon from '@mui/icons-material/Paid';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import { get_categories,get_offers_opening_time } from '../../../services/purchase_service';
import { latest_offers, latest_submissions, charts_ofBillsAmount_payed_unpayed_byMonth,
           suppliers_notes_widgetCharts, chart_of_the_most_ordered_prds,
            count_allOffers_bySupplierCategories_widget, count_all__supplierAwardedOffers_total_amount,count_all_supplierAwardedOffers_notClosed } from '../../../services/supplier_service';
import { get_widgets_view_byId_login, set_widgets_view_byId_login } from '../../../services/widgets_views_service'
import Backdrop from '@mui/material/Backdrop';

import Weather_widget from './weather_widget/weather_widget';
import Offers_table from './offers_table/offers_table';
import Last_submissions from './submissions_tables/last_submissions'
import Config_widget from './config_widget/config_widgets';
import Scheduler_widget from './scheduler_widget/scheduler_widget';
import Charts_ofBillsAmount_payed_unpayed from './charts_ofBillsAmount_payed_unpayed/charts_ofBillsAmount_payed_unpayed';
import Contracts_table from './contracts_table/contracts_table';
import Suppliers_notes_charts from './suppliers_notes_charts/suppliers_notes_charts';
import Bills_count_byState from './bills_count_byState/bills_count_byState';
import OffersChart_byCategorie from './offersChart_byCategorie/offersChart_byCategorie';
import Count_offers_totalAmount from './count_offers_totalAmount/count_offers_totalAmount';
import Count_all_supplierAwardedOffers_notClosed from './count_all_supplierAwardedOffers_notClosed/count_all_supplierAwardedOffers_notClosed';

import RSS_widget from './rss_widget/rss_widget'
import Bills_table from './bills_table/bills_table';
import Orders_number_byState from './orders_number_byState/orders_number_byState';
import Orders_totalAmount_byState from './orders_totalAmount_byState/orders_totalAmount_byState';
import Chart_of_the_most_ordered_prds from './chart_of_the_most_ordered_prds/chart_of_the_most_ordered_prds';
import { Fab, Tooltip } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1),},
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    width: '100%',
  },
  input: {
    display: 'none',
  },
  container: {
    maxHeight: 440,
  },
  select: {
    "&:focus": {
      backgroundColor: "white"
    }
  },
  selectInput: {
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottomWidth: 0
    },
    "&:after": {
      borderBottomWidth: 0
    }
  },
  disabled: {},
  focused: {},
  error: {}
}));


//////////////////////
///////////////////////////////////
function get_opening_start_DatetTme(opening_date){
  let date = new Date(opening_date);
  return new Date(date.getFullYear(),date.getMonth(),date.getDate(),date.getHours(),date.getMinutes()); 
}

function get_opening_end_DatetTme(opening_date){
  let date = new Date(opening_date);
  return new Date(date.getFullYear(),date.getMonth(),date.getDate(),(date.getHours()+2),date.getMinutes()); 
}

function random_hex_color_code(random_number){
  let n = (random_number * 0xffffff * 10000000).toString(16);
  return '#' + n.slice(0, 6);
};


function Get_widget_icon_byId(widgets_main_array,id_widget){
  return widgets_main_array.find(function(widget) {
    return widget.id === id_widget;
  }).icon;
}


//////////////////////
///////////////////////////////////



export default function Widget_contents_container() {

  const classes = useStyles();
  const selectInputClasses = {
    root: classes.selectInput,
    disabled: classes.disabled,
    focused: classes.focused,
    error: classes.error
  };

  const width_ref = useRef(null);

  let supplier = JSON.parse(localStorage.getItem('user-info'));
  let origin_url =  `https://rss.nytimes.com/services/xml/rss/nyt/World.xml`;
  let history = useHistory();
  const [url_updated , setUrl_updated] = useState(null);

  const [widgets_timeRange,setWidgets_timeRange] = useState(null);
  const [all_data_loaded,setAll_data_loaded] = useState(false);

  const [widgets_tmp_array,setWidgets_tmp_array] = useState(null);
  const [widgets_lastPosition,setWidgets_lastPosition] = useState([0,0]);

  const [city,setCity] = useState(null);
  const [latitude,setLatitude] = useState(null);
  const [longitude,setLongitude] = useState(null);
  const [news, setNews] = useState(null);

  const [sheduler_events,setSheduler_events]=useState([]);
  const [categories,setCategories]=useState([]);
  const [lastoffers,setLastoffers]=useState(null);

  const [lastsubmissions,setLastsubmissions]=useState(null);
  const [charts_ofBillsAmount_payed_unpayed_data,setCharts_ofBillsAmount_payed_unpayed_data]=useState(null);
  const [delay_between_bills_expired_payed_dates_data,setDelay_between_bills_expired_payed_dates_data]=useState(null);
  const [suppliers_notes_widgetCharts_data,setSuppliers_notes_widgetCharts_data]=useState(null);
  const [offersCharts_byCategorie,setOffersCharts_byCategorie]=useState(null);
  const [count_all__supplierAwardedOffers_total_amount_data,setCount_all__supplierAwardedOffers_total_amount_data] = useState(null);
  const [count_all_supplierAwardedOffers_notClosed_data,setCount_all_supplierAwardedOffers_notClosed_data] = useState(null);
  const [most_ordered_prds,setmost_ordered_prds] = useState(null);
  
  const [count_non_displayed,setCount_non_displayed] = useState(0);
  const [waiting, setWaiting] = useState(false);


  ////////////////////////////////////////////////////////////////////////////////////////
  
  const [remain_widgets,setRemain_Widgets] = useState();

  let widgets_main_array = [
    //{id:"weather",itemTitle:"Météo",icon:<CloudIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 19, minW: 5,minH: 18.5 }},
    //{id:"news",itemTitle:"Actualités",icon:<NewspaperIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 23, minW: 5,minH: 22 }},
    {id:"scheduler",itemTitle:"Agenda",icon:<EventIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 23, minW: 5,minH: 22 }},
    {id:"suppliers_notes_widgetCharts",itemTitle:"Vos scores",icon:<NoteIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"latest_offers",itemTitle:"Appels d'offres récents",icon:<ListAltIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"offersCharts_byCategorie",itemTitle:"Appels d'offre par famille d'achat",icon:<ListAltIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"latest_submissions",itemTitle:"Vos soumissions",icon:<ListAltIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"charts_ofBillsAmount_payed_unpayed",itemTitle:"Montant des factures réglées par mois",icon:<BarChartIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"delay_between_bills_expired_payed_dates",itemTitle:"Retard de réglement des factures par rapport à la date d'échéance",icon:<AccessTimeIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"contracts_table",itemTitle:"Vos contrats",icon:<ListAltIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"billsWidget_by_supplier",itemTitle:"Vos factures",icon:<CreditCardIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"count_all__supplierAwardedOffers_total_amount",itemTitle:"Montant total de tous les appels d'offres adjugées",icon:<WorkIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"count_all_supplierAwardedOffers_notClosed",itemTitle:"Nombre d'appels d'offres adjugées non clôturées",icon:<NumbersIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"count_orders_byState",itemTitle:"Vos commandes",icon:<NumbersIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"orders_totalAmount_byState",itemTitle:"Montant total des commandes",icon:<PaidIcon  className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"chart_of_the_most_ordered_prds",itemTitle:"Produits les plus commandés",icon:<FormatListBulletedIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 3, h: 17, minW: 3,minH: 12,isDraggable: true }}
  ];

  
  let widgets_main_tmp_array = [
    {id:"suppliers_notes_widgetCharts",itemTitle:"Vos scores",icon:<NoteIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"latest_offers",itemTitle:"Appels d'offres récents",icon:<ListAltIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"latest_submissions",itemTitle:"Vos soumissions",icon:<ListAltIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"charts_ofBillsAmount_payed_unpayed",itemTitle:"Montant des factures réglées par mois",icon:<BarChartIcon />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"contracts_table",itemTitle:"Vos contrats",icon:<ListAltIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }},
    {id:"billsWidget_by_supplier",itemTitle:"Vos factures",icon:<CreditCardIcon className="my_icon" />,dataGrid:{ x: 0, y: 0, w: 6, h: 17, minW: 4.5,minH: 12 }}
  ];



  const load_widgets_data = useCallback(async (widgets,supplier_infos,time_range) => {
    for(let i in widgets){
      let widget = widgets[i];
      if(widget.id==="weather"){
        const location = window.navigator && window.navigator.geolocation

        if (location) {
          await location.getCurrentPosition((position) => {
              ////console.log(position);
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
                axios('https://www.mapquestapi.com/geocoding/v1/reverse?key=G1moSFJkXvMTf7kCVqTOPMh1SxtvJaGi&location='+position.coords.latitude+'%2C'+position.coords.longitude+'&outFormat=json&thumbMaps=false')
                    .then((response) => {
                      setCity(response.data.results[0].locations[0].adminArea5);
                    });
            }, (error) => {
                //console.log(error);
            })
        }

      }
      if(widget.id==="news"){
        await fetch(origin_url)
        .then(response => response.text())
        .then(data => {
            let tmp_array = [];
            const parser = new DOMParser();
            const xml = parser.parseFromString(data, "text/xml");
            for(let i=0;i<6;i++){
                tmp_array.push(xml.getElementsByTagName("item")[i]);
            }

            setNews(tmp_array);
        })
        .catch(console.error);   
      }
      if(widget.id==="scheduler"){
        let tmp_list=[];
        let random_number = 0;
        await get_offers_opening_time(supplier_infos.id).then((response)=>{
              response.data.map((elem,id) => {
                let event = {
                  id_event : "offer_"+id,
                  start: get_opening_start_DatetTme(elem.dateOuvertureOffre),
                  end: get_opening_end_DatetTme(elem.dateOuvertureOffre), 
                  title: elem.object, 
                  familleAchat: elem.familleAchat, 
                  direction:elem.direction,
                  color:random_hex_color_code(random_number)
                }
                tmp_list.push(event);
                random_number+=1.03; 
              });
              setSheduler_events(tmp_list);
          }).catch((error)=>{
              //console.log(error);
          });
      }
      if(widget.id==="latest_offers"){
        await latest_offers(supplier_infos.id,time_range).then((response_offers)=> {
            setLastoffers(response_offers.data);
        }).catch((error) => {
            ////console.log(error.response);
        });
      }
      if(widget.id==="latest_submissions"){
        await latest_submissions(supplier_infos.id,time_range).then((response_submissions)=> {
          setLastsubmissions(response_submissions.data);
        }).catch((error) => {
          ////console.log(error.response);
        });
      }
      if(widget.id==="charts_ofBillsAmount_payed_unpayed"){
        await charts_ofBillsAmount_payed_unpayed_byMonth(supplier_infos.id,time_range).then((response_submissions)=> {
          setCharts_ofBillsAmount_payed_unpayed_data(response_submissions.data);
        }).catch((error) => {
          ////console.log(error.response);
        });
      }
      if(widget.id==="suppliers_notes_widgetCharts"){
        await suppliers_notes_widgetCharts(supplier.id,time_range).then((response)=>{
          setSuppliers_notes_widgetCharts_data(response.data);
        }).catch((error)=>{
          ////console.log(error.response);
        });
      }
      if(widget.id==="offersCharts_byCategorie"){
        await count_allOffers_bySupplierCategories_widget(supplier.id,time_range).then((response)=>{
          setOffersCharts_byCategorie(response.data);
        }).catch((error)=>{
          ////console.log(error.response);
        });
      }
      if(widget.id==="count_all__supplierAwardedOffers_total_amount"){
        await count_all__supplierAwardedOffers_total_amount(supplier.id,time_range).then((response)=>{
          setCount_all__supplierAwardedOffers_total_amount_data(response.data);
        }).catch((error)=>{
          ////console.log(error.response);
        });
      }
      if(widget.id==="count_all_supplierAwardedOffers_notClosed"){
        await count_all_supplierAwardedOffers_notClosed(supplier.id,time_range).then((response)=>{
          setCount_all_supplierAwardedOffers_notClosed_data(response.data);
        }).catch((error)=>{
          ////console.log(error.response);
        });
      }
      if(widget.id==="chart_of_the_most_ordered_prds"){
        await chart_of_the_most_ordered_prds(supplier.id,time_range).then((response)=> {
          setmost_ordered_prds(response.data);
        }).catch((error) => {
          ////console.log(error.response);
        });
      }
    }
    
  },[])


  const set_categories =  useCallback(async () => {
    await get_categories().then((response)=> {
      setCategories(response.data);
    }).catch((error)=>{
      ////console.log(error.response);
    });
  },[]);

  
   useEffect(() => {
      setWidgets_tmp_array(null);
      setAll_data_loaded(false);
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      var time_range = urlParams.get('intervalle_temps')?urlParams.get('intervalle_temps'):"mois";

      async function set_time(time_range){
        await setWidgets_timeRange(time_range);
      }

      async function setData(){
        set_categories();
        const widget_data = await get_widgets_view_byId_login(supplier.id).then((response)=>{
          return response.data;
        }).catch((error)=>{
          ////console.log(error.response);
        });
        if(widget_data === "empty"){
          await load_widgets_data(widgets_main_tmp_array,supplier,time_range);
          await setWidgets_tmp_array(set_widgets_grides_data(widgets_main_tmp_array));
          await setCount_non_displayed(widgets_main_array.length-widgets_main_tmp_array.length);
        }else{
          await setCount_non_displayed(widgets_main_array.length-widget_data.length);
          await load_widgets_data(widget_data,supplier,time_range);
          await setWidgets_tmp_array(widget_data);
        }
      }
      async function data_loaded(){
        setAll_data_loaded(true);
      }
      async function async_funct(){
        await set_time(time_range);
        await setData();
        await data_loaded();
      }
      
      async_funct();

  },[url_updated,history.location]);



  function set_widgets_grides_data(widgets_array){
    let tmp_array = [];
    for(let i=0 ; i<widgets_array.length ; i++){
      let previous_elem_width = 0;
      let previous_elem_height = 0;
      let previous_elem_x = 0;
      let previous_elem_y = 0;
      if(i===0){
        tmp_array.push(widgets_array[i]);
        setWidgets_lastPosition([widgets_array[i].dataGrid.x + widgets_array[i].dataGrid.w,widgets_array[i].dataGrid.y + widgets_array[i].dataGrid.h])
      }
      else{
        previous_elem_width = widgets_array[i-1].dataGrid.w;
        previous_elem_height = widgets_array[i-1].dataGrid.h;
        previous_elem_x = widgets_array[i-1].dataGrid.x;
        previous_elem_y = widgets_array[i-1].dataGrid.y;
        if((previous_elem_x + previous_elem_width + widgets_array[i].dataGrid.w)<=12){
          widgets_array[i].dataGrid.x = previous_elem_width+previous_elem_x;
          widgets_array[i].dataGrid.y = previous_elem_y;
        }else{
          if( previous_elem_y != widgets_array[i].dataGrid.y ) widgets_array[i].dataGrid.x = 0;
          widgets_array[i].dataGrid.y = previous_elem_height+previous_elem_y;
        }
        setWidgets_lastPosition([widgets_array[i].dataGrid.x + widgets_array[i].dataGrid.w,widgets_array[i].dataGrid.y + widgets_array[i].dataGrid.h])
        tmp_array.push(widgets_array[i]);
      }
    }

    ////console.log(widgets_lastPosition);
    return tmp_array;
  }
    
  const get_widgets_data_to_save = useCallback((widgets) => {
    let tmp_array = [];
    let tmp_json = {};
    widgets.map((widget)=>{
      tmp_json = {};
      tmp_json.id = widget.id;
      tmp_json.itemTitle = widget.itemTitle;
      tmp_json.dataGrid = widget.dataGrid;
      tmp_array.push(tmp_json);
    })
    return tmp_array;
  },[])


  const removeWidget = useCallback((idx) => {
    setCount_non_displayed(count_non_displayed+1);
    setWidgets_tmp_array(current =>
      current.filter(widget => {
        return widget.id !== idx;
      })
    );
  },[count_non_displayed])


  const show_remain_widgets = () => {
    let tmp_array = [];
    widgets_main_array.forEach(main_widget => {
      let exist = false;
      widgets_tmp_array && widgets_tmp_array.map((tmp_widget)=>{
        if(main_widget.id === tmp_widget.id) exist = true;
      })
      if(!exist) tmp_array.push(main_widget);
    });
    setRemain_Widgets(tmp_array);
  }


  const add_widget = useCallback(async (remain_widget)=>{
    if(widgets_tmp_array ){
      let tmp_array = [ ...widgets_tmp_array];
      let array_previous_lenght = tmp_array.length;
      if(array_previous_lenght!=0){
        let previous_widgets_lastPosition_elem_x = widgets_lastPosition[0];
        let previous_widgets_lastPosition_elem_y = widgets_lastPosition[1];
        if((previous_widgets_lastPosition_elem_x + remain_widget.dataGrid.w)<=12){
          remain_widget.dataGrid.x = previous_widgets_lastPosition_elem_x;
          remain_widget.dataGrid.y = previous_widgets_lastPosition_elem_y;
        }else{
          remain_widget.dataGrid.y = previous_widgets_lastPosition_elem_y;
        }
      }
      await setWidgets_lastPosition([remain_widget.dataGrid.x + remain_widget.dataGrid.w,remain_widget.dataGrid.y + remain_widget.dataGrid.h]);
      await setRemain_Widgets(null);
      setWaiting(true);   
      await load_widgets_data([remain_widget],supplier,widgets_timeRange);
      setWaiting(false);   
      setWidgets_tmp_array([...widgets_tmp_array, remain_widget]);
      await setCount_non_displayed(count_non_displayed-1);
    }
  },[widgets_tmp_array])

  

  const set_widgets_gridData = useCallback(async (dataGrids)=>{
    if(widgets_tmp_array ){
      let tmp_array = [...widgets_tmp_array];
      for(let i=0 ; i<tmp_array.length ; i++){
        for(let i in dataGrids){
          let dataGrid = dataGrids[i];
          if(tmp_array[i].id===dataGrid.i){
          if(tmp_array[i].dataGrid.x !== dataGrid.x || tmp_array[i].dataGrid.y !== dataGrid.y || tmp_array[i].dataGrid.w !== dataGrid.w || tmp_array[i].dataGrid.h !== dataGrid.h){
              tmp_array[i].dataGrid = dataGrid;
              if(tmp_array[i].dataGrid.x + tmp_array[i].dataGrid.w > widgets_lastPosition[0] && tmp_array[i].dataGrid.y + tmp_array[i].dataGrid.h >= widgets_lastPosition[1] ){
                await setWidgets_lastPosition([tmp_array[i].dataGrid.x + tmp_array[i].dataGrid.w,tmp_array[i].dataGrid.y + tmp_array[i].dataGrid.h]);
              }
            }
          }
        }
      }
    }
  },[widgets_lastPosition, widgets_tmp_array])



  const set_widgets_view  = useCallback((purchaseService_infos,widgets_tmp_array) => {
    setWaiting(true);
    let widgets_data_to_save = get_widgets_data_to_save(widgets_tmp_array);
    let formData = new FormData();
    formData.append('id_login',purchaseService_infos.id);
    formData.append('widgets_view',JSON.stringify(widgets_data_to_save));
    set_widgets_view_byId_login(formData).then((response)=>{
       setWaiting(false);
    }).catch((error)=>{
      ////console.log(error.response);
    });
  },[])
 
  function set_timeRange(value){
    if(value!==widgets_timeRange){
     let path = history.location.pathname;
     history.push(path+"?intervalle_temps="+value); 
     setUrl_updated(value);
    }
   }
 
   let lastClick = 0;
   const stop_dragging = useCallback(async (e,id)=>{
     let date = new Date();
     let time = date.getTime();
     const time_between_taps = 40;
     if (time - lastClick < time_between_taps) {
     }else{    
       e.stopPropagation();
       if(widgets_tmp_array ){
         let tmp_array = [ ...widgets_tmp_array ];
         tmp_array[id].dataGrid.isDraggable=false;
       }
     }
     lastClick = time;
 
   },[widgets_tmp_array])
 
   const active_dragging  = useCallback(async (id)=>{
     if(widgets_tmp_array ){
       let tmp_array = [ ...widgets_tmp_array ];
       tmp_array[id].dataGrid.isDraggable=true;
     }
   },[widgets_tmp_array])
 
   const cachedValue = useMemo(() => {
     return widgets_tmp_array;
   }, [widgets_tmp_array])
 
   const widgets_timeRange_call =useMemo(() => {
     return widgets_timeRange;
   }, [widgets_timeRange]);
   
 
   const test = useMemo(() => { 
     return(
     <React.Fragment>
     {
       cachedValue!=null && all_data_loaded===true
        ?<Responsive
          className="layout"
          breakpoints={{ lg: 1130, md: 870, sm: 670, xs: 400, xxs: 0 }}
          cols={{ lg: 12, md: 11, sm: 9, xs: 6, xxs: 6 }}
          style={{width:"100%",height:"99%"}}
          margin={{ lg: [14,17], md: [10,15], sm: [9,14], xs: [8,13], xxs: [7,11] }}
          rowHeight={1}
          width={width_ref.current.offsetWidth - 4}
          autoSize={true}
          onLayoutChange={(e) => { set_widgets_gridData(e) }}
        >
            {
              cachedValue.map((widget,idx)=>{
                  return (
                    <div className="widget_container" key={widget.id} id={idx} data-grid={widget.dataGrid}>
                        <div className='item_title' style={{display:widget.id==="weather"?"none":"flex"}} >
                           <div style={{display:'flex'}}>
                             <span style={{display:'flex',marginRight:'5px',justifyContent:'center',alignItems:'center'}} >{Get_widget_icon_byId(widgets_main_array,widget.id)}</span>
                             <span style={{lineHeight:'.95rem',alignSelf:'center'}}>{widget.itemTitle}</span>
                           </div>
                           <div>
                               <CloseSharpIcon onTouchStart={(e)=>{e.stopPropagation();removeWidget(widget.id)}} 
                                 onClick={(e)=>{e.stopPropagation();e.preventDefault();removeWidget(widget.id)}} className="my_icon" style={{cursor: "pointer"}} />
                           </div>
                       </div> 
                       <div className="widget_component_container" id={"widget_component_container"+idx}
                         onTouchStartCapture={(e)=>{stop_dragging(e,idx);
                             e.currentTarget.addEventListener('touchend',()=>{
                               active_dragging(idx);
                           }, {passive: false});
                         }}>
                         {
                           (widget.id==="weather" && latitude!=null && longitude!=null && city!=null)?<Weather_widget latitude={latitude} longitude={longitude} city={city} removeWidget={()=>removeWidget(widget.id)} />
                           :(widget.id==="news")?<RSS_widget news={news} />
                           :(widget.id==="scheduler" && sheduler_events!=null)?<Scheduler_widget sheduler_events={sheduler_events} categories={categories} />
                           :(widget.id==="latest_offers" && lastoffers!=null )?<Offers_table categories={categories} data={lastoffers} />
                           :(widget.id==="latest_submissions" && lastsubmissions!=null )?<Last_submissions categories={categories} data={lastsubmissions} />
                           :(widget.id==="charts_ofBillsAmount_payed_unpayed" && charts_ofBillsAmount_payed_unpayed_data!=null )?<Charts_ofBillsAmount_payed_unpayed data={charts_ofBillsAmount_payed_unpayed_data}  />
                           :(widget.id==="delay_between_bills_expired_payed_dates")?<Bills_table id_supplier={supplier.id} time_range={widgets_timeRange} />
                           :(widget.id==="contracts_table")?<Contracts_table id_supplier={supplier.id} time_range={widgets_timeRange} />
                           :(widget.id==="suppliers_notes_widgetCharts" && suppliers_notes_widgetCharts_data!=null )?<Suppliers_notes_charts data={suppliers_notes_widgetCharts_data} />
                           :(widget.id==="offersCharts_byCategorie" && offersCharts_byCategorie!=null)?<OffersChart_byCategorie categories={categories} data={offersCharts_byCategorie} />
                           :(widget.id==="billsWidget_by_supplier")?<Bills_count_byState id_supplier={supplier.id} time_range={widgets_timeRange}/>
                           :(widget.id==="orders_totalAmount_byState")?<Orders_totalAmount_byState id_supplier={supplier.id} time_range={widgets_timeRange}/>
                           :(widget.id==="count_all__supplierAwardedOffers_total_amount" && count_all__supplierAwardedOffers_total_amount_data!=null)?<Count_offers_totalAmount categories={categories} data={count_all__supplierAwardedOffers_total_amount_data} />
                           :(widget.id==="count_all_supplierAwardedOffers_notClosed" && count_all_supplierAwardedOffers_notClosed_data!=null)?<Count_all_supplierAwardedOffers_notClosed categories={categories} data={count_all_supplierAwardedOffers_notClosed_data} />
                           :(widget.id==="count_orders_byState")?<Orders_number_byState id_supplier={supplier.id} time_range={widgets_timeRange} />
                           :(widget.id==="chart_of_the_most_ordered_prds" && most_ordered_prds!==null)?<Chart_of_the_most_ordered_prds data={most_ordered_prds} />
                           :<></>
                         } 
                       </div>
                    </div>
                  )
              })
             }    
                    
        </Responsive>
        :<Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        } 
        </React.Fragment>
   )
   },[cachedValue,all_data_loaded]);
 
   return (
    <>
     <div className="widgets_main_container" style={{height:"100%"}}>
       <div className="widgets_view_title">
          <div className='main_title'>Tableau de bord</div>
          <div className="widgets_configButton_container">
            {
              widgets_timeRange_call!=null
              ?<div className="month_year_switchercontainer">
                  <Button variant="contained" className={widgets_timeRange_call==="mois"?"selected_button":"unselected_button"} onClick={()=>set_timeRange("mois")} > Mois </Button>
                  <FormControl variant="standard" className="year_slct_form" >
                    <Select required value={widgets_timeRange_call.split("_")[0]==="annee" && widgets_timeRange_call.split("_").length!==1?widgets_timeRange_call:"annee"} 
                          onChange={e => { set_timeRange(e.target.value) }}
                          input={<Input classes={selectInputClasses} />}
                          inputProps={{ classes: { select: classes.select } }}
                          className={widgets_timeRange_call.split("_")[0]==="annee" && widgets_timeRange_call.split("_").length!==1?"active_select":"inactive_select"}
                          >
                        
                        <MenuItem value={"annee"} disabled>Annee</MenuItem>
                        <MenuItem value={"annee_"+(new Date()).getFullYear()}>{(new Date()).getFullYear()}</MenuItem>
                        <MenuItem value={"annee_"+((new Date()).getFullYear()-1)}>{(new Date()).getFullYear()-1}</MenuItem>
                        <MenuItem value={"annee_"+((new Date()).getFullYear()-2)}>{(new Date()).getFullYear()-2}</MenuItem>
                        
                    </Select>
                  </FormControl>
                </div>
              :<></>
            }
            <div className="widgets_configButtons">
              <Tooltip title="Sauvegarder">
                  <Fab className="widgets_saveButton" onClick={() => set_widgets_view(supplier,widgets_tmp_array)} >
                    <SaveAsIcon className="my_icon" />
                  </Fab>
                </Tooltip>
              {
                count_non_displayed === 0
                ?<Tooltip title="Widget">
                  <Fab className="widgets_configButton disabled_button" >
                    <DashboardCustomizeIcon className="my_icon" />
                  </Fab>
                </Tooltip>
                :<Tooltip title="Widget">
                  <Fab className="widgets_configButton" onClick={show_remain_widgets} >
                    <DashboardCustomizeIcon className="my_icon" />
                  </Fab>
                </Tooltip>
              }
            </div>
              
              
          </div>
       </div>
  
        
        <div key="main" className="widgets_contents_container" ref={width_ref}>
                {test}
        </div>
      </div>
  
      {
  
        waiting===true
        ?<Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={waiting}
          >
              <CircularProgress color="inherit" />
          </Backdrop>
        :<></>
      }
  
        {
          remain_widgets!=null
          ?<Config_widget add_widget={add_widget} remain_widgets={remain_widgets} setRemain_Widgets={setRemain_Widgets} widgets_tmp_array={cachedValue} /> 
          :<></>
        }
  
      </>
        
    );
  }