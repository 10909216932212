import '../../../../../assets/css/order_details.css';
import React, { useEffect,useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import swal from 'sweetalert';
import Button from '@material-ui/core/Button';
import Form from 'react-bootstrap/Form'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { pdf } from '@react-pdf/renderer';
import empty from '../../../../empty.jpg'


import {makeStyles} from '@material-ui/core/styles';
import Loading_animation_view from '../../../../loading_animation_view/loading_animation_view';
import { get_categories, service_confirm_with_supplier,get_order_infos_for_file } from '../../../../../services/purchase_service';
import { add_SelectedSupplier_to_purchase_request } from '../../../../../services/for_allServices';
import Set_refuse_reason from './set_refuse_reason/set_refuse_reason';
import Order_form_header_pdf from './order_form_pdf/order_form_header_pdf';


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'product', label: "Produit", minWidth: 0,align: 'left'  },
    { id: 'unit_amount', label: "Prix unitaire (MAD)", minWidth: 0,align: 'left'  },
    { id: 'product_promo', label: "Promo (%)", minWidth: 0,align: 'left'  },
    { id: 'count', label: "Quantité", minWidth: 0 ,align: 'left' },
    { id: 'amount', label: "Prix total (MAD)", minWidth: 0,align: 'left'  }
  ];


function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
  
function Products({categories,products,set_price_by_unit,set_amount_by_unit,set_promo_by_unit,disable_updates}){
    

    function getCategorieById(id){
        var categorie="";
        categories.map((catg)=>{
          if(catg.id_categorie === id) categorie = catg.nom;
        })
        return categorie;
    }

    return(
        <>
        {
            products.map((product,id) => (
                <TableRow key={"product_col"+id}>
                    <TableCell align="left">
                        <div style={{display:"flex",alignItems:"center"}}>
                           <span className='product_cell_img_container'>
                                <img src={product.product_pics.length!==0?`data:image/png;base64,${product.product_pics[0]}`:empty} />
                            </span>
                            <span className='product_cell_title_container'>
                                <span id="title">{product.title}</span>
                                <div className='product_cell_categorie_container'>
                                     <span>Famille d'achat : </span>{getCategorieById(product.id_categorie)}
                                </div> 
                            </span> 
                        </div>
                        
                    </TableCell>
                    <TableCell align="left">
                      <Form.Control
                          className="amount_container"
                          autoComplete="new-password"
                          type="number"
                          placeholder='0'
                          value={product.product_price}
                          onChange={(e)=>set_price_by_unit(e.target.value,id)}
                          readOnly={disable_updates}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Form.Control
                          className="amount_container"
                          autoComplete="new-password"
                          type="number"
                          placeholder='0'
                          value={product.product_promo}
                          onChange={(e)=>set_promo_by_unit(e.target.value,id)}
                          readOnly
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Form.Control
                          className="amount_container"
                          autoComplete="new-password"
                          type="number"
                          value={product.product_amount}
                          onChange={(e)=>set_amount_by_unit(e.target.value,id)}
                          readOnly={disable_updates}
                      />
                    </TableCell>
                    <TableCell align="left">
                        <Form.Control
                            className="amount_container"
                            autoComplete="new-password"
                            type="number"
                            value={
                              (
                                product.product_price!=='' && !isNaN(product.product_price) 
                                    ?(product.product_amount*(product.product_price - ((product.product_promo!=='' && !isNaN(product.product_promo))?(product.product_price*product.product_promo)/100:0)))
                                    :0
                              )
                            }
                            readOnly
                        />
                    </TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}



function Order_details({selected_products_details,request_code,request_state_tmp,id_supplier,products_bysupplier,setProducts_bysupplier,order_key,order_number}) {

     let service =  JSON.parse(localStorage.getItem('user-info'))

    const classes = useStyles();
    const [categories,setCategories]=useState([]);
    const [products,setProducts]=useState(null);

    const [total_ht,setTotal_ht]=useState(0);
    const [vat,setvat]=useState(0);
    const [total_ttc,setTotal_ttc]=useState(0);
    const [shippingCosts,setshippingCosts]=useState(0);
    const [discount,setdiscount]=useState(0);

    const [disable_updates,setDisable_updates]=useState(false);

    const [order_state,setOrder_state]=useState(null);
    const [open_reason_modal,setOpen_reason_modal]=useState(null);

    const [wait_submitting,setWait_submitting] = useState(false);


    useEffect(()=>{
        get_categories().then((response)=>{
            setCategories(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        });

        //console.log(selected_products_details.vat);
        async function set_vat_value(val){
          setvat(val);
        }
        async function set_shipping_value(val){
          setshippingCosts(val);
        }
        async function set_discount_value(val){
          setdiscount(val);
        }
        async function set_products_value(vat,shippingCosts,discount,val){
          setProducts(val);
          set_total_amountHT(vat,shippingCosts,discount,val);
        }

        setOrder_state(selected_products_details.order_state?selected_products_details.order_state:"null");

        setDisable_updates(request_state_tmp=="demander à être changée"?false:selected_products_details.order_state?
                    selected_products_details.order_state!==null && selected_products_details.order_state!=="refusée"
                    && selected_products_details.order_state!=="sous réserve de validation" && selected_products_details.order_state!=="validation refusée" 
                    ?true:false:false);

        set_vat_value(selected_products_details.vat);
        set_shipping_value(selected_products_details.shippingCosts);
        set_discount_value(selected_products_details.discount);
        set_products_value(selected_products_details.vat,selected_products_details.shippingCosts,selected_products_details.discount,selected_products_details.products);
        
    },[]);


    function set_amount_by_unit(amount,id){
      let tmp_array = [ ...products ];
      tmp_array[id].product_amount = amount<0?0:parseInt(amount);
      setProducts(tmp_array);
      set_total_amountHT(vat,shippingCosts,discount,tmp_array)
    }


    function set_price_by_unit(price,id){
      let tmp_array = [ ...products ];
      tmp_array[id].product_price = price<0?0:parseInt(price);
      setProducts(tmp_array);
      set_total_amountHT(vat,shippingCosts,discount,tmp_array)
    }

    function set_promo_by_unit(promo,id){
      let tmp_array = [ ...products ];
      tmp_array[id].product_promo = promo<0?0:parseInt(promo);
      setProducts(tmp_array);
      set_total_amountHT(vat,shippingCosts,discount,tmp_array)
    }

    async function set_total_amountHT(vat,shippingCosts,discount,products){
      let tmp_total = 0;
      products.map((product) => {
        tmp_total += (product.product_price!=='' && !isNaN(product.product_price) ?(product.product_amount * (product.product_price - ((product.product_promo!=='' && !isNaN(product.product_promo))?(product.product_price*product.product_promo)/100:0))):0);
      });
      setTotal_ht(tmp_total);
      set_total_amountTTC(vat,shippingCosts,discount,tmp_total)

    }


    async function set_total_amountTTC(vat,shippingCosts,discount,total_ht){
      setvat(vat<0?0:parseInt(vat));
      setshippingCosts(shippingCosts<0?0:parseInt(shippingCosts));
      setdiscount(discount<0?0:parseInt(discount));
      let tmp_vat = ((total_ht*(vat!=="" && !isNaN(vat)?vat:0))/100);
      let tmp_shippingCosts = ((total_ht*(shippingCosts!=="" && !isNaN(shippingCosts)?shippingCosts:0))/100);
      let tmp_discount = ((total_ht*(discount!=="" && !isNaN(discount)?discount:0))/100);
      let tmp_total = total_ht + tmp_vat + tmp_shippingCosts - tmp_discount;
      setTotal_ttc(tmp_total.toFixed(2));
    }

    function notify_supplier(){
      let products_tmp = [];
      products.map((product) => {
        products_tmp.push({
              "product_code" : product.product_code,
              "product_promo" : !isNaN(product.product_promo)?product.product_promo:0,
              "product_price" : !isNaN(product.product_price)?product.product_price:0,
              "product_amount": !isNaN(product.product_amount)?product.product_amount:0,
            })
      });
      let order_infos = {
        "products" : products_tmp,
        "discount" : !isNaN(discount)?discount:0,
        "shippingCosts" : !isNaN(shippingCosts)?shippingCosts:0,
        "vat" : !isNaN(vat)?vat:0,
      }
      setWait_submitting(true);
      let formData = new FormData();
      formData.append('request_code',request_code);
      formData.append('id_supplier',products[0].id_supplier);
      formData.append('products',JSON.stringify(order_infos));
      add_SelectedSupplier_to_purchase_request(formData).then((response)=>{
        //console.log(response.data);
        setWait_submitting(false);
        swal("le fournisseur sera bientôt notifié !", {
          icon: "success",
          });
      }).catch((error)=>{
        //console.log(error.response);
        setWait_submitting(false);
      })
    }


    function accept_supplier_request(id_supplier,order_number){
      swal({
          title: "Etes-vous sûr ?",
          text: "Une fois que vous avez soumis votre réponse, vous ne pourrez plus la modifier !",
          icon: "warning",
          buttons: true,
          dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          async function sendit(){
            setWait_submitting(true);
            var order_data = null;
            let products_tmp = [];
            products.map((product) => {
            products_tmp.push({
                    "product_code" : product.product_code,
                    "title" : product.title,
                    "product_promo" : !isNaN(product.product_promo)?product.product_promo:0,
                    "product_price" : !isNaN(product.product_price)?product.product_price:0,
                    "product_amount": !isNaN(product.product_amount)?product.product_amount:0,
                  })
            });
            let order_infos = {
              "products" : products_tmp,
              "discount" : !isNaN(discount)?discount:0,
              "shippingCosts" : !isNaN(shippingCosts)?shippingCosts:0,
              "vat" : !isNaN(vat)?vat:0,
            }
            let formData2 = new FormData();
            formData2.append('request_code',request_code);
            formData2.append('id_supplier',id_supplier);
            await get_order_infos_for_file(formData2).then((response)=>{
              order_data = response.data;
              order_data['products'] = order_infos["products"];
              order_data['total_ht'] = !isNaN(total_ht)?total_ht:0;
              order_data['vat'] = order_infos["vat"];
              order_data['total_ttc'] = !isNaN(total_ttc)?total_ttc:0;
              order_data['shippingCosts'] = order_infos["shippingCosts"];
              order_data['discount'] = order_infos["discount"];
            }).catch((error)=>{
              //console.log(error.response);
            });
            const blob = await pdf((
                <Order_form_header_pdf order_data={order_data} />
            )).toBlob();

            let formData = new FormData();
            formData.append('request_code',request_code);
            formData.append('id_supplier',id_supplier);
            formData.append('id_service',service.id);
            formData.append('products',JSON.stringify(order_infos));
            formData.append('accord',true);
            formData.append('order_blob',blob); 
            formData.append('order_number',order_number); 
            service_confirm_with_supplier(formData).then((response)=>{
              //console.log(response.data);
              setOrder_state("acceptée");
              setWait_submitting(false);
              swal("votre réponse a été enregistrée avec succès et le fournisseur sera bientôt notifié !", {
                icon: "success",
                });
              let tmp_products = { ...products_bysupplier };
              tmp_products[order_key].service_accord = true;
              tmp_products[order_key].order_state = "acceptée";
              setProducts_bysupplier(tmp_products);
            }).catch((error)=>{
              //console.log(error.response);
              setWait_submitting(false);
            })
          }
          sendit();
        }
      });
    
    }


    function refuse_supplier_request(id_supplier){
      let formData = new FormData();
      formData.append('request_code',request_code);
      formData.append('id_supplier',id_supplier);
      formData.append('id_service',service.id);
      formData.append('accord',false);
      setOpen_reason_modal(formData);
    }



//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

  return (
    <>
    {
      products===null
      ?<Loading_animation_view />
      :<div className="order_plus_details_container">
         <TableContainer className="order_plus_details_table_container">
            <Table className={classes.table}>
                <TableHead>
                <TableRow>
                    {
                        columns.map((column) => (
                            <TableCell key={column.id} align={column.align}>{column.label}</TableCell>
                        ))
                    }
                </TableRow>
                </TableHead>
                <TableBody>
                {
                    selected_products_details.length!==0
                    ?
                    <>
                        <Products categories={categories} products={products} set_price_by_unit={set_price_by_unit} set_amount_by_unit={set_amount_by_unit} set_promo_by_unit={set_promo_by_unit} disable_updates={disable_updates} />
                    </>
                            
                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun produit disponible</div>
                }
                </TableBody> 
            </Table>
        </TableContainer> 
        <div className='total_amount_container'>
          <TableContainer className="total_amount_table_container">
              <Table className={classes.table}>
                  <TableBody>
                  <TableRow>
                        <TableCell align={"left"}>Total HT (MAD)</TableCell>
                        <TableCell align={"right"}>{numberWithSpaces(total_ht)+" MAD"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align={"left"}>Taux de TVA (%)</TableCell>
                        <TableCell align={"right"}>
                            <Form.Control
                                className="amount_container"
                                autoComplete="new-password"
                                type="number"
                                placeholder='0'
                                value={vat}
                                onChange={(e)=>{set_total_amountTTC(e.target.value,shippingCosts,discount,total_ht);}}
                                readOnly={disable_updates}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align={"left"}>Frais d’expédition (%)</TableCell>
                        <TableCell align={"right"}>
                        <Form.Control
                                className="amount_container"
                                autoComplete="new-password"
                                type="number"
                                placeholder='0'
                                value={shippingCosts}
                                onChange={(e)=>{set_total_amountTTC(vat,e.target.value,discount,total_ht);}}
                                readOnly
                            />
                          </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align={"left"}>Remise (%)</TableCell>
                        <TableCell align={"right"}>
                          <Form.Control
                                className="amount_container"
                                autoComplete="new-password"
                                type="number"
                                placeholder='0'
                                value={discount}
                                onChange={(e)=>{set_total_amountTTC(vat,shippingCosts,e.target.value,total_ht);}}
                                readOnly={disable_updates}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align={"left"}>Total TTC (MAD)</TableCell>
                        <TableCell align={"right"}>{numberWithSpaces(total_ttc)+" MAD"}</TableCell>
                    </TableRow>
                  </TableBody> 
              </Table>
          </TableContainer> 
        </div>
        <div className="proceed_button_container">
          {
              order_state!==null
              ?<>
                {
                  order_state==="sous réserve de validation"
                  ?<>
                      <Button variant="contained" className={wait_submitting?"submit_but cancel_but disabled_button":"submit_but cancel_but"} onClick={()=>refuse_supplier_request(id_supplier)}> 
                              Refuser
                      </Button>
                      <Button variant="contained" className={wait_submitting?"submit_but valide_but disabled_button":"submit_but valide_but"} onClick={()=>accept_supplier_request(id_supplier,order_number)}> 
                              Valider
                      </Button>
                  </>
                  :<>
                   {
                      order_state==="null" || request_state_tmp=="demander à être changée"
                      ?<Button variant="contained" className={wait_submitting?"submit_but disabled_button":"submit_but"} endIcon={<NavigateNextIcon className="my_icon" />} onClick={notify_supplier}> 
                                Notifier le fournisseur
                        </Button>
                      :<></>
                    }
                  </>
                }
              </>
              :<></>
            }
        </div>
      </div>
    }
    {
      open_reason_modal!==null
      ?<Set_refuse_reason open_reason_modal={open_reason_modal} setOpen_reason_modal={setOpen_reason_modal}
                              products_bysupplier={products_bysupplier} setProducts_bysupplier={setProducts_bysupplier} order_key={order_key} />
      :<></>
    }

    <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait_submitting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
  );
}

export default Order_details;
